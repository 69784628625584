import React, { useEffect, Component } from "react";
import "../styling/pages.css";
import Heder from "../components/navigation-header";
import Footer from "../components/footer";
//import SearchBar from "../components/search-bar/BadComponent";
import BadImg from "../images/img.png";

export default function Bad() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <Heder />

      <center>
        <div className="section-background">
          <div className="bad-section">
            <center>
              <div>
                <img
                  src={BadImg}
                  alt="Logo"
                  style={{ width: "300", height: "300px", marginTop: "10px" }}
                />
              </div>
              <h1 className="bad">
                Oops! The page you are looking for has been recycled.
              </h1>
              <p className="bad-para">
                Please visit our homepage or sitemap to find the information you
                are looking for.
              </p>
              {/* <div>
                <SearchBar />
              </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <a
                  href="/"
                  style={{
                    color: "#fff",
                    backgroundColor: "#313893",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                    width: "150px",
                    textAlign: "center",
                    fontFamily: '"Assistant", sans-serif',
                    fontSize: "20px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#1c5258";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#313893";
                  }}
                >
                  Home Page
                </a>
                <a
                  href="/sitemap/"
                  style={{
                    color: "#fff",
                    backgroundColor: "#313893",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                    width: "150px",
                    textAlign: "center",
                    fontFamily: '"Assistant", sans-serif',
                    fontSize: "20px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#1c5258";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#313893";
                  }}
                >
                  Sitemap
                </a>
                <br />
                <br />
              </div>
            </center>
          </div>
        </div>
      </center>

      <Footer />
    </div>
  );
}
